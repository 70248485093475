import {
  getEmailLogsDescription,
  getUserLogDescription,
  getOnboardingUserLogDescription,
  translateDescription,
  getVerifyPhoneLogDescription,
  getNewUsersEmailLogsDescription,
  getUserPermissioneLogDescription,
} from "@/helpers/services/logs";

export const expandableContexts = ["edit_tags", "edit_user_tags"];

export const LOG_CONTEXT = {
  USER_PERMISSION: "user_permission",
  COMPANY_PLAN: "company_plan",
  USER_DELEGATES: "user_delegates",
  EDIT_TAGS: "edit_tags",
  EDIT_USER_TAGS: "edit_user_tags",
  user_delegates: "Delegação de acesso",
  edit_tags: "Edição de informações",
  edit_user_tags: "Edição de informações de usuário",
  add_new_user: "Criação de usuário",
  onboarding_new_user: "Criação de usuário",
  update_user: "Atualização de usuário",
  delete_user: "Exclusão de usuário",
  apply_email_signature: "Aplicação de assinatura",
  request_email_signature: "Solicitação de aplicação",
  default_signature_for_new_user: "Assinatura para novos usuários",
  reapply_email_signature: "Reaplicação de assinatura",
  apply_secondary_email_signature:
    "Aplicação de assinatura no email secundário",
  declined_signature: "Aplicação de assinatura",
  accepted_signature: "Aplicação de assinatura",
  verify_phone: "Verificação de telefone",
  new_users_signature: "Assinatura para novos usuários",
  user_permission: "Permissão de usuário",
};

export const LOG_TRANSCRIPTION = {
  NEW_USERS_SIGNATURE: " Assinatura para novos usuários",
  EMAIL_SIGNATURE: "assinatura de e-mail",
  APPLY_EMAIL_SIGNATURE: "aplicou assinatura de email",
  USER: "Usuário",
  UPDATED: "Atualizado",
  CREATED: "Criado",
  DELETED: "Excluído",
  user_delegates: "Delegação de acesso",
  edit_tags: "Edição de informações",
  edit_user_tags: "Edição de informações de usuário",
  DELETE: "Exclusão de",
  CREATE: "Delegação de",
  ACCESS: " acesso",
  FROM: " da conta ",
  TO: " para",
  EDITED_TAGS: " editou as informações de assinatura da empresa",
  EDITED_USER_TAGS: " editou as informações de assinatura do usuário ",
  APPLIED: " aplicou",
  NOT_FOUND: "não encontrado",
  None: "Usuário não encontrado",
};

export const LOG_ICON_BY_ACTION = {
  CREATE: "mdi-plus-circle",
  DELETE: "mdi-minus-circle",
  ADD_USER_PERMISSION: "mdi-plus-circle",
  REMOVE_USER_PERMISSION: "mdi-minus-circle",
};

export const LOG_ICON_COLOR_BY_ACTION = {
  CREATE: "success",
  DELETE: "error",
  ADD_USER_PERMISSION: "success",
  REMOVE_USER_PERMISSION: "red",
};

export const LOG_ICON_BY_STATUS_CODE = {
  SUCCESS: "mdi-shield-check",
  EXPIRED_CODE: "mdi-shield-alert",
  INVALID_CODE: "mdi-shield-off",
  UNKNOWN_ERROR: "mdi-shield-off",
  TOO_MANY_REQUESTS: "mdi-clock",
};

export const LOG_ICON_COLOR_BY_STATUS_CODE = {
  SUCCESS: "success",
  EXPIRED_CODE: "accent",
  INVALID_CODE: "error",
  UNKNOWN_ERROR: "error",
  TOO_MANY_REQUESTS: "error",
};

export const LOG_CODE = {
  user_delegates: {
    event: "Delegação de acesso",
    action: "", // Alguns logs tem um único contexto com multiplas ações, portanto utilize a ação do log nesses casos
    icon: "",
    color: "",
    description: "",
    getDescription: translateDescription,
  },

  new_users_signature: {
    event: "Assinatura de novos usuários",
    action: "APPLY",
    icon: "mdi-email-check",
    color: "success",
    description:
      "Os novos usuários receberam uma assinatura de email padrão: {IMPACTED_USERS}",
    getDescription: getNewUsersEmailLogsDescription,
  },

  edit_tags: {
    event: "Edição de informações",
    action: "EDIT_TAGS",
    icon: "mdi-pencil",
    color: "accent",
    description: "",
    getDescription: translateDescription,
  },

  edit_user_tags: {
    event: "Edição de informações de usuário",
    action: "EDITED_USER_TAGS",
    icon: "mdi-pencil",
    color: "accent",
    description: "",
    getDescription: translateDescription,
  },

  apply_email_signature: {
    event: "Aplicação de assinatura",
    action: "APPLY",
    icon: "mdi-email-check",
    color: "success",
    description: "{AUTHOR} aplicou assinatura de email para {IMPACTED_USERS}",
    getDescription: getEmailLogsDescription,
  },
  request_email_signature: {
    event: "Solicitação de aplicação",
    action: "REQUESTED",
    icon: "mdi-email-fast",
    color: "accent",
    description:
      "{AUTHOR} solicitou aplicação da assinatura de e-mail para os usuários: {IMPACTED_USERS}",
    getDescription: getEmailLogsDescription,
  },
  default_signature_for_new_user: {
    event: "Assinatura para novos usuários",
    action: "APPLY",
    icon: "mdi-email-check",
    color: "success",
    description:
      "Assinatura para novos usuários foi aplicada automaticamente para {IMPACTED_USERS}",
    getDescription: getEmailLogsDescription,
  },
  reapply_email_signature: {
    event: "Reaplicação de assinatura",
    action: "REAPPLY",
    icon: "mdi-email-check",
    color: "success",
    description:
      "{AUTHOR} reaplicou a assinatura de email para {IMPACTED_USERS}",
    getDescription: getEmailLogsDescription,
  },
  apply_secondary_email_signature: {
    event: "Aplicação de assinatura no email secundário",
    action: "APPLY",
    icon: "mdi-email-check",
    color: "success",
    description:
      "{AUTHOR} aplicou a assinatura no email secundário para {IMPACTED_USERS}",
    getDescription: getEmailLogsDescription,
  },
  declined_signature: {
    event: "Aplicação de assinatura",
    action: "DECLINED",
    icon: "mdi-email-remove",
    color: "error",
    description:
      "{AUTHOR} rejeitou aplicar a assinatura solicitada por {IMPACTED_USERS}",
    getDescription: getEmailLogsDescription,
  },
  accepted_signature: {
    event: "Aplicação de assinatura",
    action: "ACCEPTED",
    icon: "mdi-email-check",
    color: "success",
    description:
      "{AUTHOR} aceitou aplicar a assinatura solicitada por {IMPACTED_USERS}",
    getDescription: getEmailLogsDescription,
  },
  add_new_user: {
    event: "Criação de usuário",
    action: "ADD_NEW_USER",
    icon: "mdi-plus-circle",
    color: "success",
    description: "{AUTHOR} criou {USER}",
    getDescription: getUserLogDescription,
  },
  onboarding_new_user: {
    event: "Criação de usuário",
    action: "ADD_NEW_USER",
    icon: "mdi-plus-circle",
    color: "success",
    description: "{AUTHOR} criou {USER}",
    getDescription: getOnboardingUserLogDescription,
  },
  update_user: {
    event: "Atualização de usuário",
    action: "UPDATE_USER",
    icon: "mdi-pencil",
    color: "accent",
    description: "{AUTHOR} atualizou {USER}",
    getDescription: getUserLogDescription,
  },
  delete_user: {
    event: "Exclusão de usuário",
    action: "DELETE_USER",
    icon: "mdi-minus-circle",
    color: "error",
    description: "{AUTHOR} Excluiu {USER}",
    getDescription: getUserLogDescription,
  },
  verify_phone: {
    event: "Exclusão de usuário",
    action: "",
    icon: "",
    color: "",
    description: "Telefone {PHONE} enviado para verificação",
    getDescription: getVerifyPhoneLogDescription,
  },
  user_permission: {
    event: "Permissão de usuário",
    action: "",
    icon: "",
    color: "",
    descriptionAdd:
      "O usuário {USER} recebeu a permissão de gerente para {PRODUCT}",
    descriptionRemove:
      "A permissão de gerente para {PRODUCT}, foi removida do usuário {USER}",
    getDescription: getUserPermissioneLogDescription,
  },
};

export const LOG_ACTION = {
  DELETE: "Exclusão",
  CREATE: "Criação",
  UPDATE: "Atualização",
  EDIT_TAGS: "Edição",
  EDITED_USER_TAGS: "Edição",
  EDIT: "Edição",
  APPLY: "Aplicação",
  REAPPLY: "Reaplicação",
  EDIT_USER_TAGS: "Edição",
  DECLINED: "Recusado",
  ACCEPTED: "Aceito",
  REQUESTED: "Solicitação",
  ADD_NEW_USER: "Criação",
  UPDATE_USER: "Atualização",
  DELETE_USER: "Exclusão",
  SUCCESS: "Sucesso",
  EXPIRED_CODE: "Código expirado",
  INVALID_CODE: "Código inválido",
  UNKNOWN_ERROR: "Erro desconhecido",
  TOO_MANY_REQUESTS: "Muitas tentativas",
  NOT_FOUND: "Não encontrado",
  REMOVE_USER_PERMISSION: "Removeu",
  ADD_USER_PERMISSION: "Concedeu",
};
